<template>
  <div class="reserve" :class="!isJoin ? 'yu-yue' : ''">
    <!--    <div class="f-13 logout" @click="logout" v-if="token">退出登录</div>-->
    <div class="f-13 rules" @click="toPage('rules')">活动规则</div>
    <template v-if="isJoin">
      <!--  已预约    -->
      <div v-if="status === 1">
        <div class="yi-yuy-ue">
          <img :src="require('../assets/img/yiyueyue.png')" width="57%" class="status1"/>
        </div>
        <div class="text-center white f-17 pici">轻松小镇首次发行数字藏品</div>
        <div class="white text-center f-20">
          - 已预约
          <span v-for="(item, index) in number" :key="index">{{item ? item : ''}}</span>
          人 -
        </div>
        <div class="letter">RESERVE</div>
        <div class="my-code">
          <!--        我的中签码-->
          <div class="wrap my" v-if="status === 1">
            <div class="text-center f-16 bold m-b-10">点亮元气<br>参与限量数字藏品抢占资格抽签</div>
            <div class="f-12 text-center m-b-20">{{announce}}正式发售</div>
            <div class="six">
              <div class="six-one" :class="item ? 'has' : ''" @click="toPage('share')"
                   v-for="(item, index) in codeList" :key="index">
                <div class="vigor" v-if="item">
                  <img :src="require('../assets/img/reserve-vigor.png')" width="100%" height="100%"/>
                </div>
                <div class="no-code" v-else>
                  <van-icon name="plus" color="white" size="30"/>
                </div>
                <div class="f-10 text-center m-t-5">{{item ? item : '待点亮'}}</div>
              </div>
            </div>
            <div class="invited m-b-10" @click="checkResult" v-if="isGetResult">
              <span style="position: relative;z-index: 1;">查看结果</span>
            </div>
            <div class="invited m-b-10" @click="toPage('share')" v-else>
              <span style="position: relative;z-index: 1;">点亮元气</span>
            </div>
            <div class="f-10 text-center m-b-5">点亮元气越多，抢占时中签概率越大</div>
            <van-notice-bar left-icon="volume-o" :scrollable="false"
                            color="black" v-if="helpList.length"
                            background="radial-gradient(rgba(255,255,255,.82), rgba(255,255,255,0))">
              <van-swipe
                vertical
                class="notice-swipe"
                :autoplay="3000"
                :show-indicators="false"
              >
                <van-swipe-item class="f-11 text-center"
                                style="width: 100%;"
                                v-for="(item, index) in helpList" :key="index">
                  {{item.mobile.slice(0, 2)}} **** {{item.mobile.slice(7, 10)}}  为您多点亮一缕元气  {{item.num}}
                </van-swipe-item>
              </van-swipe>
            </van-notice-bar>
          </div>
          <!--        已中签-->
          <div class="wrap has" v-else-if="status === 2">
            <div class="f-16 bold text-center">我的中签码</div>
            <div class="bold my-has">{{jackpot}}</div>
            <div class="linqu m-b-10">
              <span style="position: relative;z-index: 1;">立即领取</span>
            </div>
            <van-notice-bar style="width: 100%;" color="#559AE9" background="radial-gradient(rgba(255,255,255,.82), rgba(255,255,255,0))">
              <span class="f-11">请于{{lingTime}}前领取，超时未领取则视为自动放弃</span>
            </van-notice-bar>
          </div>
          <!--      没有中签  -->
          <div class="wrap not" v-else-if="status === 3">
            <div class="f-16 bold text-center">下次活动继续努力</div>
            <template v-if="isShowNext">
              <div class="yue m-b-10" @click="reserveNext">
                <span style="position: relative;z-index: 1;">提前预约下批活动</span>
              </div>
              <van-notice-bar style="width: 100%;" color="#3B3B3B" background="radial-gradient(rgba(255,255,255,.82), rgba(255,255,255,0))">
                <span class="f-11">轻松小镇本期活动结束，下一期活动于{{nextTime}}开始</span>
              </van-notice-bar>
            </template>
          </div>
          <!--   已预约下批活动   -->
          <div class="wrap next" v-else-if="status === 4">
            <div class="yue m-b-10">
              <span style="position: relative;z-index: 1;">已预约下批活动</span>
            </div>
            <van-notice-bar style="width: 100%;" color="#3B3B3B" background="radial-gradient(rgba(255,255,255,.82), rgba(255,255,255,0))">
              <span class="f-11">轻松小镇本期活动结束，下一期活动于{{nextTime}}开始</span>
            </van-notice-bar>
          </div>
          <!--   没有参加本次活动   -->
          <div class="wrap no-join" v-else>
            <template v-if="isShowNext">
              <div class="yue m-b-10" @click="reserveNext">
                <span style="position: relative;z-index: 1;">提前预约下批活动</span>
              </div>
              <van-notice-bar style="width: 100%;" color="#3B3B3B" background="radial-gradient(rgba(255,255,255,.82), rgba(255,255,255,0))">
                <span class="f-11">轻松小镇本期活动结束，下一期活动于{{nextTime}}开始</span>
              </van-notice-bar>
            </template>
          </div>
        </div>
        <div class="shequ">
          <div class="cloud3">
            <img :src="require('../assets/img/cloud.png')" width="40%"/>
          </div>
          <div class="cloud4">
            <img :src="require('../assets/img/cloud2.png')" width="40%"/>
          </div>
          <div class="text-center bold f-16">官方社区</div>
          <div class="f-12 des text-center">加入用户共创社区<br>第一时间获取小镇最新资讯</div>
          <div class="erweima">
            <img :src="require('../assets/img/kefu.png')" width="100%" height="100%"/>
          </div>
        </div>
      </div>
      <!--  中签    -->
      <template v-if="status === 2">
        <status2 :code="jackpot"></status2>
      </template>
      <!--  未中签    -->
      <template v-if="status === 3" class="status3">
        <status3></status3>
      </template>
    </template>
    <template v-else>
      <div class="town-name">
        <img :src="require('../assets/img/town.png')"/>
      </div>
      <div class="reserve-btn" @click="reserve" v-if="isShowOrder">抢先预约&nbsp;<span class="f-10 period">{{period}}</span></div>
      <div class="reserve-btn" v-else>预约已结束&nbsp;<span class="f-10 period">{{period}}</span></div>
      <div class="f-13 text-center des">{{des}}</div>
      <div class="number">
        <div class="f-15 bold text-center">已预约成功</div>
        <div class="many">
          <span class="item" :class="item === 0 ? 'small-gray' : ''"
                v-for="(item, index) in number" :key="index">{{item}}</span>
          <span class="bold f-13 person">人</span>
        </div>
        <div class="cloud">
          <img :src="require('../assets/img/cloud.png')" width="100%" height="100%"/>
        </div>
        <div class="cloud2">
          <img :src="require('../assets/img/cloud2.png')" width="100%" height="100%"/>
        </div>
      </div>
    </template>
    <template v-if="status === 1">
      <div class="time">
        <div class="active">Active Time</div>
        <div class="text-center f-15 bold title">活动时间</div>
        <div class="line">
          <span class="block" :class="blockPos === 2 ? 'block-two' : 'block'"></span>
        </div>
        <van-row class="step text-center">
          <van-col span="12">
            <div class="specific text-center">{{rTime}}-{{aTime}}</div>
            <div class="f-12 text-center">预约抽签</div>
          </van-col>
          <!--        <van-col span="8">-->
          <!--          <div class="specific text-center" style="background: linear-gradient(90deg, #94BFFF, #6ABDFF);-webkit-background-clip: text;">-->
          <!--            {{aTime}}</d.lineiv>-->
          <!--          <div class="f-12 text-center">公布中签码</div>-->
          <!--        </van-col>-->
          <van-col span="12">
            <div class="specific text-center" style="background: linear-gradient(90deg, #5BC0FF, #29E8D0);-webkit-background-clip: text;">
              {{aTime}}</div>
            <div class="f-12 text-center">
              正式发售
            </div>
          </van-col>
        </van-row>
      </div>
      <div class="instruction">
        <div class="card2">
          <video :src="require( Qimg +'reserve-video.mp4')"
                 id="myVideo"
                 :poster="require('../assets/img/video-poster.jpg')"
                 loop autoplay playsinline controls
                 x5-video-player-type="h5" preload="auto"
                 x5-video-player-fullscreen="true"
                 width="100%" height="100%"></video>
          <!--          <div class="f-12 text-center">守护用户健康的第三数字空间-->
          <!--            <br>-->
          <!--            轻松小镇首次铸造发行数字藏品</div>-->
        </div>
        <div class="card2-text">
          <div class="bold title text-center">姜小竹珍藏款数字艾灸盒</div>
          <div class="f-21 text-center m-t-10 m-b-10">古法中医 x 未来科技</div>
          <div class="f-13 text-center m-b-25">年度爆款｜限量发行｜珍稀藏品</div>
          <div class="f-15">
            本数字藏品基于倍轻松旗下年度热销款产品“姜小竹”的“3D纪念版数字模型”原始设计稿发行铸造。“姜小竹”一经推出，创造了全网16万+的销售业绩。此次轻松小镇联合倍轻松推出的“姜小竹数字艾灸盒”数字藏品仅限量铸造360台，十分稀缺，具有较高的收藏与纪念意义。
            <br>
            其中299台用于本次公开预约抽签活动，剩余61台将保留用于未来奖励为小镇作出突出贡献的用户和战略伙伴。
          </div>
        </div>
      </div>
      <div class="wenan">
        <div class="m-t-25 m-b-10 icon">
          <img :src="require('../assets/img/icon2.png')"/>
          参与内测 优先入驻
        </div>
        <div class="f-12">
          “姜小竹数字艾灸盒”数字藏品是轻松小镇内测发行的首款数字藏品。参与本次数字藏品预约抽签活动的用户，将被视作轻松小镇内测阶段的重要参与者，成为未来轻松小镇app内测官，有权提前入驻轻松小镇APP。
        </div>
        <div class="m-t-25 m-b-10 icon">
          <img :src="require('../assets/img/icon1.png')"/>
          限量铸造 永不增发
        </div>
        <div class="f-12">
          “姜小竹数字艾灸盒”数字藏品仅限量铸造360台，仅有299台用于本次公开预约抽签活动，其余将作为用户未来贡献奖励保留。每台数字艾灸盒都是纪念款特殊数字藏品，具有限量纪念收藏价值。
        </div>
        <div class="m-t-25 m-b-10 icon">
          <img :src="require('../assets/img/icon3.png')"/>
          同链流转 身份证明
        </div>
        <div class="f-12">
          “姜小竹数字艾灸盒”数字藏品，由腾讯至信链提供发行技术支持，拥有唯一编号和不可篡改的区块信息。未来可在小红书、QQ音乐等同链生态平台中流转展示，成为数字时代社交身份的珍稀证明。
        </div>
        <div class="m-t-25 m-b-10 icon">
          <img :src="require('../assets/img/icon4.png')"/>
          专属绑定 优先体验
        </div>
        <div class="f-12">
          中签后，“姜小竹数字艾灸盒”系列数字藏品将与持有用户专属绑定，未来用户可抢先体验倍轻松推出的「物联网版按摩器」。
        </div>
      </div>
    </template>
    <tabbar :active="0"></tabbar>
    <login :show="isShowLogin" @close="isShowLogin = false" @success="isShowLogin = false"></login>
    <!--  预约弹窗  -->
    <van-overlay :show="isShowReserve" @click="isShowReserve = false" z-index="10">
      <div class="wrapper">
        <div class="block reserve-dlg">
          <div class="close">
            <van-icon name="cross" size="15" color="#69686D" @click="isShowReserve = false"/>
          </div>
          <div>
            <div class="m-b-5 f-25 bold text-center">预约成功</div>
            <div class="f-15 text-center">【送您一个轻松盲盒】</div>
          </div>
          <div class="white text-center">
            前往个人中心-轻松盲盒拆开<br>收集元石可兑换轻松盲盒
          </div>
        </div>
      </div>
    </van-overlay>
    <!--  邀请好友成功弹窗  -->
    <van-overlay :show="isShowInvited" @click="isShowInvited = false" z-index="10">
      <div class="wrapper">
        <div class="block invited">
          <div class="close">
            <van-icon name="cross" size="15" color="#69686D" @click="isShowInvited = false"/>
          </div>
          <div class="m-b-10 f-25 bold text-center">好友预约成功</div>
          <div class="f-15 text-center">送你{{read.length * stone}}元石，{{read.length}}个抽签码</div>
          <div class="friend">
            <span v-for="(item, index) in read" :key="index">
              <span class="friend-code" v-if="item.number">{{item.number}}</span>
            </span>
          </div>
          <div class="stone">
            <img :src="require('../assets/img/tone-dlg.png')" width="100%" height="100%"/>
          </div>
          <div class="btn">好的</div>
        </div>
      </div>
    </van-overlay>
  </div>
</template>

<script src="http://res.wx.qq.com/open/js/jweixin-1.0.0.js"></script>
<script>
  import Login from '../components/Login'
  import Tabbar from '../components/tabbar'
  import { mapMutations, mapState } from 'vuex'
  import { getWxConfig } from '../../tool'
  import { reserveStatus, reserveApi, getResultApi } from '../api/user.js'
  import Status2 from '../components/Status2'
  import Status3 from '../components/Status3'
  const monent = require('moment')
  export default {
    name: 'Reserve',
    components: { Login, Tabbar, Status2, Status3 },
    data () {
      return {
        isShowLogin: false,
        isShowReserve: false,
        isShowInvited: false,
        status: 1, // 我的中签状态 1.已预约 2.已中签  3.未中签 4.已预约下一期 5.没有参加本次活动
        number: [], // 已经预约多少人
        period: '', // 第几期活动
        des: '', // 活动的描述
        blockPos: 1, // 活动进行到哪一步
        rTime: '', // y预约时间
        aTime: '', // 公布时间 未预约情况下
        gTime: '', // 领取时间
        activityId: localStorage.getItem('activityId'), // 获得id
        codeList: [], // 抽签码列表
        helpList: [], // 邀请朋友列表
        jackpot: null, // 中奖码
        announce: '', // 已预约情况下公布中签时间
        lingTime: '', // 中签的领取时间
        nextTime: '', // 下一次活动开始时间
        isShowNext: false, // 是否有显示下一期活动
        nextAcId: '', // 下一期活动id
        read: [], // 好友弹窗抽签码
        stone: 0, // 每个好有奖励多少元石
        isJoin: false, // 是否加入了本期活动
        isShowOrder: true, // 是否还可预约
        isGetResult: false
      }
    },
    computed: {
      ...mapState({
        token: state => state.token
      })
    },
    async created () {
      await this.getReserveInfo()
      const now = new Date().getTime()
      const start = localStorage.getItem('time2')
      if (now < start) {
        this.isShowOrder = true
      } else {
        this.isShowOrder = false
      }
      if (now > localStorage.getItem('time2')) {
        this.isGetResult = true
      } else {
        this.isGetResult = false
      }
      if (this.token) {
        if (now >= localStorage.getItem('time2') && localStorage.getItem('hasResult')) {
          console.log('开奖了')
          this.checkResult()
        } else if (this.isJoin) {
          this.getMyReserveInfo()
        }
      }
      getWxConfig()
    },
    mounted () {
      // ios可以 安卓不可以
      document.addEventListener('WeixinJSBridgeReady', function () {
        var media = document.getElementById('myVideo')
        media.play()
      }, false)
    },
    destroyed () {
      document.removeEventListener('WeixinJSBridgeReady', function () {}, false)
    },
    methods: {
      ...mapMutations({
        vxSetUser: 'setUser'
      }),
      logout () {
        localStorage.clear()
        location.reload()
      },
      toPage (name) {
        this.$router.push({
          name
        })
      },
      async checkResult () {
        try {
          const res = await getResultApi({
            reserve_id: this.activityId
          })
          localStorage.setItem('hasResult', true)
          if (res.data.jackpot) {
            this.status = 2
            this.jackpot = res.data.num
          } else {
            this.status = 3
          }
        } catch (e) {
          console.log(e)
          this.$toast(e.data)
        }
      },
      async reserve () {
        if (this.token) {
          await this.getReserveInfo()
          this.getMyReserveInfo()
        } else {
          this.isShowLogin = true
        }
      },
      //  提前预约下一批活动
      async reserveNext () {
        try {
          const res = await reserveApi({
            reserve_id: this.nextAcId
          })
          this.status = 4
          this.period = res.data.reserve[1].period
          this.isShowReserve = true
          console.log(res)
        } catch (e) {
          console.log(e)
          this.$toast(e.data)
        }
      },
      async getMyReserveInfo () {
        try {
          const res = await reserveApi({
            reserve_id: this.activityId
          })
          this.read = []
          this.stone = res.data.store
          this.status = 1
          // 得到公布时间
          const time = new Date(res.data.reserve[0].time4 * 1000)
          this.announce = `${time.getFullYear()}年${time.getMonth() + 1}月${time.getDate()}日${time.getHours()}:${time.getMinutes()}${time.getSeconds()}`
          this.helpList = res.data.help_list
          this.helpList.forEach(item => {
            if (item.read) {
              this.read.push({ number: item.num })
            }
          })
          if (this.read.length) {
            this.isShowInvited = true
          }
          this.codeList = res.data.num
          this.codeList.length = 6
          this.isShowLogin = false
          console.log('this.isJoin:', this.isJoin)
          if (!this.isJoin) {
            this.isShowReserve = true
          }
          this.isJoin = true
        } catch (e) {
          console.log(e)
          this.isShowLogin = false
          // if (e.code === -1) {
          //   this.getResult()
          // } else {
          //   this.$toast.fail(e.data)
          // }
        }
      },
      async getResult () {
        try {
          const res = await getResultApi({
            reserve_id: this.activityId
          })
          this.isJoin = true
          // join_one: 是否参加本期活动 jackpot：是否中奖 join：是否预约下一次
          // 预约了下一次
          if (res.data.join) {
            this.status = 4
            this.period = res.data.reserve_id.period
            const next = new Date(res.data.reserve_id.time1 * 1000)
            this.nextTime = `${next.getFullYear()}年${next.getMonth() + 1}月${next.getDate()}日`
            return
          }
          // 没有预约下一次
          // 没有参加本次活动
          if (!res.data.join_one) {
            this.status = 5
            this.isShowNext = true
            if (res.data.reserve_id) {
              const next = new Date(res.data.reserve_id.time1 * 1000)
              this.nextTime = `${next.getFullYear()}年${next.getMonth() + 1}月${next.getDate()}日`
              this.nextAcId = res.data.reserve_id.id
            }
          } else {
            // 参见了本期活动
            // 中奖了
            if (res.data.jackpot) {
              this.status = 2
              this.jackpot = res.data.num
              const time = localStorage.getItem('time3')
              this.lingTime = `${time.getFullYear()}年${time.getMonth() + 1}月${time.getDate()}日`
            } else {
              // 没中奖
              // 没预约
              this.status = 3
              this.isShowNext = true
              if (res.data.reserve_id) {
                const next = new Date(res.data.reserve_id.time1 * 1000)
                this.nextTime = `${next.getFullYear()}年${next.getMonth() + 1}月${next.getDate()}日`
                this.nextAcId = res.data.reserve_id.id
              }
            }
          }
        } catch (e) {
          console.log(e)
        }
      },
      async getReserveInfo () {
        try {
          const res = await reserveStatus({})
          this.isJoin = res.data.join_reserve
          this.activityId = res.data.reserve[0].id
          // 获得已预约人数
          const minLength = 6
          let number = res.data.join.toString().split('')
          const length = res.data.join.toString().length
          const a = []
          if (length <= minLength) {
            for (let i = 0; i < minLength - length; i++) {
              a[i] = 0
            }
            number = a.concat(number)
            number.splice(3, 0, ',')
            this.number = number
          }
          // 活动时间段
          this.des = res.data.reserve[0].content
          this.period = res.data.reserve[0].period
          this.getThreeTime(res.data.reserve[0].time1, res.data.reserve[0].time2, res.data.reserve[0].time3)
          localStorage.setItem('time4', res.data.reserve[0].time4 * 1000)
          localStorage.setItem('time5', res.data.reserve[0].time5 * 1000)
        } catch (e) {
          console.log(e)
        }
      },
      getThreeTime (time1, time2, time3) {
        localStorage.setItem('time1', time1 * 1000)
        localStorage.setItem('time2', time2 * 1000)
        localStorage.setItem('time3', time3 * 1000)
        // 活动进行到那一步
        const now = parseInt(new Date().getTime() / 1000)
        if (now < time2) {
          this.blockPos = 1
        } else {
          this.blockPos = 2
        }
        this.rTime = this.getTime(time1, 'MM.DD')
        this.aTime = this.getTime(time2, 'MM.DD')
        this.gTime = this.getTime(time3, 'MM.DD')
      },
      getTime (timeStamp, format) {
        timeStamp = timeStamp + ''
        timeStamp = timeStamp.length === 10 ? timeStamp * 1000 : timeStamp
        const date = new Date(timeStamp)
        return monent(date).format(format)
      }
    }
  }
</script>

<style scoped lang="scss">
  .reserve{
    min-height: 100vh;
    background-image: url("../assets/img/rrrr-bg.jpg");
    background-repeat: no-repeat;
    background-size: 100% auto;
    position: relative;
    color: black;
    padding-bottom: 30px;
    overflow: hidden;
    background-color: #F7FCFC;
    .logout{
      width: 76px;
      height: 30px;
      text-align: center;
      position: absolute;
      right: 0;
      top: 30px;
      background: url("../assets/img/logout.png") no-repeat;
      background-size: contain;
      color: white;
      line-height: 30px;
      font-size: 11px;
    }
    .rules{
      position: absolute;
      right: 0;
      top: 30px;
      background-image: linear-gradient(to bottom,rgba(165, 208, 254, 0.99), rgba(226, 208, 216, 0.99));
      border: 1px solid rgba(255,255,255,.1);
      box-shadow: 3px 8px 19px 1px rgba(43, 96, 128, 0.42);
      opacity: 0.8;
      border-radius: 3px 0 0 3px;
      width: 23px;
      padding: 10px 5px;
      color: white;
    }
    .r-top{
      top: 180px;
    }
    .town-name{
      text-align: center;
      margin-top: 460px;
      >img{
        width: 60%;
        height: 60%;
      }
    }
    .yi-yuy-ue{
      text-align: center;
      margin-top: 380px;
      height: 98px;
      .status1{
        margin-top: -15px;
      }
      .mei{
        font-size: 37px;
        color: rgba(255,255,255,.5);
        line-height: 72px;
      }
    }
    .pici{
      margin-top: -33px;
      margin-bottom: 8px;
    }
    .letter{
      background-image: linear-gradient(90deg, #00c6ff, #df81ff);
      -webkit-background-clip:text;
      -webkit-text-fill-color:transparent;
      font-size: 50px;
      font-weight: 500;
      text-align: center;
      margin-top: 15px;
    }
    .my-code{
      margin: -40px 22px 21px;
      background: rgba(255, 255, 255, 0.3);
      box-shadow: 1px 8px 32px 1px rgba(192, 230, 252, 0.65);
      border-radius: 3px;
      padding: 9px;
      .wrap{
        background: url("../assets/img/my-code.png") no-repeat;
        background-size: cover;
        border: 1px solid #FFFFFF;
        box-shadow: 1px 8px 32px 1px rgba(175, 216, 239, 0.65);
        border-radius: 3px;
        height: 100%;
        min-height: 311px;
        .six{
          display: flex;
          flex-wrap: wrap;
          justify-content: space-between;
          .six-one{
            width: 70px;
            display: inline-block;
            text-align: center;
            font-size: 13px;
            color: #AFBFD0;
            margin-bottom: 18px;
            .no-code{
              border: 1px dashed #FFFFFF;
              border-radius: 50%;
              width: 70px;
              height: 70px;
              display: flex;
              justify-content: center;
              align-items: center;
            }
            .vigor{
              width: 70px;
              height: 70px;
            }
            &:nth-child(2n){
              margin-right: 0;
            }
          }
          .has{
            color: #434343;
            font-size: 17px;
          }
        }
        .invited{
          text-align: center;
          margin: 0 27px 12px;
          font-size: 21px;
          color: #191919;
          padding: 11px;
          border-radius: 30px;
          box-shadow: 1px 8px 32px 1px rgba(175, 216, 239, 0.65); /*background-image: linear-gradient(173deg, rgba(255, 255, 255, 0.86), rgba(249, 246, 247, 0.86), rgba(255, 255, 255, 0.86));*/
          position: relative;
          &:after{
            content: '';
            position: absolute;
            width: calc(100% - 2px);
            height: calc(100% - 2px);
            top: 1px;
            left: 1px;
            background-image: linear-gradient(to left, #D8F1FC, #E5EEFA);
            border-radius: 28px;
          }
        }
        .notice-swipe {
          height: 28px;
          line-height: 28px;
        }
      }
      .my{
        padding: 24px;
      }
      >.has{
        padding: 44px 0;
        display: flex;
        align-items: center;
        flex-direction: column;
        height: 340px;
        .my-has{
          font-weight: 500;
          color: #434343;
          font-size: 23px;
          margin: 39px 0;
        }
        .linqu{
          width: 209px;
          text-align: center;
          margin: 0 27px 35px;
          font-size: 21px;
          color: #191919;
          padding: 11px;
          border-radius: 30px;
          box-shadow: 1px 8px 32px 1px rgba(175, 216, 239, 0.65);
          position: relative;
          &:after{
            content: '';
            position: absolute;
            width: calc(100% - 2px);
            height: calc(100% - 2px);
            top: 1px;
            left: 1px;
            background-image: linear-gradient(to left, #D8F1FC, #E5EEFA);
            border-radius: 28px;
          }
        }
      }
      .not{
        display: flex;
        align-items: center;
        flex-direction: column;
        padding: 88px 0px 43px;
        height: 340px;
        >.yue{
          width: 209px;
          text-align: center;
          margin: 59px 27px 35px;
          font-size: 21px;
          color: #191919;
          padding: 11px;
          border-radius: 30px;
          box-shadow: 1px 8px 32px 1px rgba(175, 216, 239, 0.65);
          position: relative;
          &:after{
            content: '';
            position: absolute;
            width: calc(100% - 2px);
            height: calc(100% - 2px);
            top: 1px;
            left: 1px;
            background-image: linear-gradient(to left, #D8F1FC, #E5EEFA);
            border-radius: 28px;
          }
        }
      }
      .next{
        display: flex;
        align-items: center;
        flex-direction: column;
        padding: 88px 0px 43px;
        height: 340px;
        >.yue{
          width: 209px;
          text-align: center;
          margin: 40px 27px 77px;
          font-size: 21px;
          color: #191919;
          padding: 11px;
          border-radius: 30px;
          box-shadow: 1px 8px 32px 1px rgba(175, 216, 239, 0.65);
          position: relative;
          &:after{
            content: '';
            position: absolute;
            width: calc(100% - 2px);
            height: calc(100% - 2px);
            top: 1px;
            left: 1px;
            background-color: #EBEBEC;
            border-radius: 28px;
          }
        }
      }

      .no-join{
        display: flex;
        align-items: center;
        flex-direction: column;
        padding: 120px 0 43px;
        justify-content: space-between;
        >.yue{
          width: 209px;
          text-align: center;
          font-size: 21px;
          color: #191919;
          padding: 11px;
          border-radius: 30px;
          box-shadow: 1px 8px 32px 1px rgba(175, 216, 239, 0.65);
          position: relative;
          &:after{
            content: '';
            position: absolute;
            width: calc(100% - 2px);
            height: calc(100% - 2px);
            top: 1px;
            left: 1px;
            background-image: linear-gradient(to left, #D8F1FC, #E5EEFA);
            border-radius: 28px;
          }
        }
      }
    }
    .shequ{
      background: url("../assets/img/erweiba.png") no-repeat;
      background-size: contain;
      height: 315px;
      padding: 54px;
      display: flex;
      flex-direction: column;
      align-items: center;
      position: relative;
      .cloud3{
        position: absolute;
        top: -5%;
        left: -20%;
      }
      .cloud4{
        position: absolute;
        bottom: 0;
        right: -104%;
      }
      >.des {
        margin: 15px 0 10px;
        padding: 5px 32px;
        background-image: radial-gradient(rgba(255,255,255,0.72), rgba(255,255,255,0));
      }
      >.erweima{
        background: rgba(255, 255, 255, 0.4);
        border: 1px solid #FFFFFF;
        box-shadow: 1px 8px 32px 1px rgba(175, 216, 239, 0.65);
        border-radius: 3px;
        padding: 6px;
        width: 104px;
        height: 104px;
      }
    }
    .h1{
      padding: 88px 70px 10px;
      margin: 0;
      font-size: 32px;
    }
    .card{
      margin: 20px 0 25px;
      height: 253px;
    }
    .reserve-btn{
      position: relative;
      background: rgba(255, 255, 255, 0.23);
      border-image: linear-gradient(173deg, rgba(255, 255, 255, 0.86), rgba(249, 246, 247, 0.86), rgba(255, 255, 255, 0.86)) 1 1;
      box-shadow: 0px 8px 20px 1px rgba(96, 190, 246, 0.2);
      border-radius: 25px;
      color: white;
      font-size: 25px;
      font-weight: 600;
      width: 242px;
      text-align: center;
      height: 51px;
      line-height: 51px;
      margin: 30px auto 24px;
      border: 1px solid rgba(255,255,255,.5);
      >.period{
        position: absolute;
        bottom: -7px;
      }
    }
    >.des{
      background-image: radial-gradient(rgba(255,255,255,.5), rgba(255,255,255,0));
      margin-bottom: 14px;
      padding: 6px;
      font-size: 11px;
    }
    .number{
      background: url("../assets/img/number.png") no-repeat;
      background-size: cover;
      height: 201px;
      background-position-x: -10px;
      padding: 40px 35px 50px;
      position: relative;
      width: 100%;
      overflow-y: visible;
      z-index: 2;
      .many{
        width: calc(100% - 17px);
        margin-top: 10px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        position: relative;
        >i{
          width: 7px;
        }
        >.item{
          padding: 0 4px;
          display: inline-block;
          font-size: 50px;
          background: linear-gradient(-48deg, #A3D0FF, #E2D0D8);
          border: 1px solid #FFFFFF;
          border-radius: 3px;
          color: white;
          text-align: center;
          &:nth-of-type(4) {
            background: none;
            border: none;
            width: 20px;
          }
        }
        >.small-gray{
          color: rgba(255,255,255,.3);
        }
        .person{
          position: absolute;
          right: -18px;
          bottom: 0;
        }
      }
      .cloud{
        position: absolute;
        width: 196px;
        height: 73px;
        bottom: -2px;
        right: -24%;
      }
      .cloud2{
        position: absolute;
        width: 170px;
        height: 56px;
        bottom: -50px;
        left: -10%;
      }
    }
    .time{
      background: url("../assets/img/time.png") no-repeat;
      background-size: cover;
      height: 260px;
      padding: 43px;
      padding-top: 110px;
      position: relative;
      margin-top: -13px;
      .active{
        position: absolute;
        color: #70B1EE;
        font-size: 7px;
        top: 118px;
        left: 50px;
        &:after{
          position: absolute;
          content: '';
          border-radius: 50%;
          width: 2px;
          height: 2px;
          left: -7px;
          background-color: #70B1EE;
          top: 50%;
        }
      }
      .line{
        margin: 15px 0;
        height: 3px;
        background: linear-gradient(90deg, #ffc3c5, #83bdff, #29e8cd);
        box-shadow: 1px 1px 3px 0px rgba(193, 233, 255, 0.4);
        border-radius: 1px;
        position: relative;
        .block{
          display: block;
          position: absolute;
          width: 15px;
          height: 15px;
          background: #FFFFFF;
          box-shadow: -3px 4px 13px 0px rgba(158, 209, 238, 0.45);
          border-radius: 50%;
          top: -50%;
          transform: translate(-100%, -30%);
          left: 28%;
        }
        .block-two{
          left: 77%;
        }
        .block-three{
          left: 86%;
        }
      }
      .step{
        .specific{
          background: linear-gradient(90deg, #FF8E95, #99BFFF);
          box-shadow: 1px 1px 3px 0px rgba(193, 233, 255, 0.4);
          color: transparent;
          -webkit-background-clip: text;
          margin-bottom: 8px;
        }
      }
    }

    .instruction{
      margin: 30px 18px;
      margin-bottom: 0;
      background: url("../assets/img/card2-bg.png") no-repeat;
      background-size: cover;
      border: 1px solid #FFFFFF;
      box-shadow: 1px 8px 32px 1px rgba(175, 216, 239, 0.65);
      border-radius: 10px;
      overflow: hidden;
      .card2{
        margin: 7px;
        height: 317px;
        /*background: url("../assets/img/card2.png") no-repeat;*/
        background-size: contain;
        border-radius: 5px;
        /*padding-top: 30px;*/
        border: 1px solid rgba(255,255,255, .5);
      }
      .card2-text{
        padding: 30px;
        >.title{
          font-size: 25px;
        }
      }
    }
    .wenan{
      padding: 28px 48px;
      .icon{
        display: flex;
        align-items: center;
        >img{
          width: 19px;
          height: 19px;
          margin-right: 5px;
        }
      }
    }
    .wrapper {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 100%;
      .block {
        width: 85%;
        background-color: #fff;
        border-radius: 10px;
        position: relative;
        .close {
          position: absolute;
          width: 27px;
          height: 27px;
          border-radius: 50%;
          background: #ECECEC;
          right: 10px;
          top: 10px;
          display: flex;
          justify-content: center;
          align-items: center;
        }
      }
      .reserve-dlg{
        background: url("../assets/img/reserve-dlg.jpg") no-repeat;
        background-size: cover;
        height: 352px;
        padding: 35px 35px 30px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
      }

      >.invited{
        background-color: black;
        display: flex;
        color: white;
        flex-direction: column;
        align-items: center;
        padding: 30px;
        .friend{
          margin-top: 20px;
          .friend-code{
            display: inline-block;
            background: rgba(255, 255, 255, 0.11);
            font-size: 13px;
            padding: 5px 15px;
            border-radius: 20px;
            margin-right: 10px;
            margin-bottom: 5px;
          }
        }
        >.stone{
          width: 120px;
          height: 120px;
          margin-top: 20px;
        }
        >.btn{
          font-size: 17px;
          font-weight: 500;
          color: #000000;
          height: 37px;
          line-height: 37px;
          text-align: center;
          padding: 0 30px;
          background: #FFFFFF;
          border-radius: 18px;
        }
      }
    }
    /deep/ .van-notice-bar{
      .van-icon {
        display: none;
      }
      .van-notice-bar__content.van-ellipsis{
        width: 100%;
        max-width: none;
      }
    }
  }
</style>
